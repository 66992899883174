define("discourse/plugins/swapd-mlm/discourse/controllers/earnings-edit-transaction", ["exports", "@ember/controller", "discourse/mixins/modal-functionality", "discourse-common/utils/decorators", "discourse/lib/ajax", "@ember/string", "@ember/utils"], function (_exports, _controller, _modalFunctionality, _decorators, _ajax, _string, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _controller.default.extend(_modalFunctionality.default, (_dec = (0, _decorators.default)("type"), _dec2 = (0, _decorators.default)("model.id"), _dec3 = (0, _decorators.default)("editTransaction"), _dec4 = (0, _decorators.default)("editTransaction", "isEdit"), _dec5 = (0, _decorators.default)("loading", "editTransaction", "formatedPrice", "value1", "value2"), _dec6 = (0, _decorators.default)("value1"), (_obj = {
    onShow() {
      this.set("loading", false);
      (0, _string.w)("type value1 value2").forEach(k => {
        this.set(k, this.get(`model.${k}`));
      });
    },
    editTransaction(type) {
      return type === 1;
    },
    isEdit(id) {
      return !(0, _utils.isBlank)(id);
    },
    placeholderValue2(editTransaction) {
      const key = editTransaction ? "title" : "comment";
      return I18n.t(`referral.${key}`);
    },
    modalTitle(editTransaction, isEdit) {
      const action = isEdit ? "edit" : "add";
      const suffix = editTransaction ? "transaction" : "comment";
      return `referral.${action}_${suffix}`;
    },
    saveDisabled(loading, editTransaction, formatedPrice, value1, value2) {
      if (loading) return true;
      if (editTransaction) return isNaN(formatedPrice) || (0, _utils.isBlank)(value2);
      return (0, _utils.isBlank)(value1) || (0, _utils.isBlank)(value2);
    },
    formatedPrice(value1) {
      return parseInt(value1 || "");
    },
    actions: {
      save() {
        if (this.get("saveDisabled")) return;
        this.set("loading", true);
        this.get("isEdit") ? this._update() : this._save();
      },
      delete() {
        if (this.get("loading")) return;
        this.set("loading", true);
        this._delete();
      }
    },
    _save() {
      (0, _ajax.ajax)("/earnings", {
        type: "POST",
        data: this._serializeData()
      }).then(result => this.appEvents.trigger("earnings:add", result)).finally(() => this.set("loading", false));
    },
    _update() {
      const id = this.get("model.id");
      (0, _ajax.ajax)(`/earnings/${id}`, {
        type: "PUT",
        data: this._serializeData()
      }).then(result => this.appEvents.trigger("earnings:update", result)).finally(() => this.set("loading", false));
    },
    _delete() {
      const id = this.get("model.id");
      (0, _ajax.ajax)(`/earnings/${id}`, {
        type: "DELETE",
        data: this._serializeData()
      }).then(result => this.appEvents.trigger("earnings:delete", result)).finally(() => this.set("loading", false));
    },
    _serializeData() {
      return {
        user_id: this.get("user_id"),
        data: {
          type: this.get("type"),
          value1: typeof this.get("value1") === 'string' ? this.get("value1").replace(',', '.') : this.get("value1"),
          value2: this.get("value2")
        }
      };
    }
  }, (_applyDecoratedDescriptor(_obj, "editTransaction", [_dec], Object.getOwnPropertyDescriptor(_obj, "editTransaction"), _obj), _applyDecoratedDescriptor(_obj, "isEdit", [_dec2], Object.getOwnPropertyDescriptor(_obj, "isEdit"), _obj), _applyDecoratedDescriptor(_obj, "placeholderValue2", [_dec3], Object.getOwnPropertyDescriptor(_obj, "placeholderValue2"), _obj), _applyDecoratedDescriptor(_obj, "modalTitle", [_dec4], Object.getOwnPropertyDescriptor(_obj, "modalTitle"), _obj), _applyDecoratedDescriptor(_obj, "saveDisabled", [_dec5], Object.getOwnPropertyDescriptor(_obj, "saveDisabled"), _obj), _applyDecoratedDescriptor(_obj, "formatedPrice", [_dec6], Object.getOwnPropertyDescriptor(_obj, "formatedPrice"), _obj)), _obj)));
});