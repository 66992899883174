define("discourse/plugins/swapd-mlm/discourse/helpers/referral", ["exports", "discourse-common/lib/helpers"], function (_exports, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _helpers.registerHelper)("eq", params => {
    return params[0] == params[1];
  });
});