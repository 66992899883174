define("discourse/plugins/swapd-mlm/discourse/helpers/earning-date", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = earningDate;
  function earningDate(date) {
    return moment(date).format("DD/MM/YYYY");
  }
});